<template>
  <div class="content5">
    <v-tour :options="tourOptions" name="linkWithApis" :steps="steps"></v-tour>
    <div class="api-title">
      <svg
        width="38"
        height="42"
        viewBox="0 0 38 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.9999 11.5554L18.9999 20.9999M18.9999 20.9999L1.99993 11.5554M18.9999 20.9999L19 39.9999M37 29.117V12.8828C37 12.1975 37 11.8549 36.899 11.5493C36.8097 11.2789 36.6637 11.0308 36.4707 10.8214C36.2526 10.5847 35.9531 10.4183 35.3541 10.0855L20.5541 1.86329C19.9869 1.54817 19.7032 1.39062 19.4029 1.32884C19.1371 1.27417 18.8629 1.27417 18.5971 1.32884C18.2968 1.39062 18.0132 1.54817 17.4459 1.86329L2.64594 10.0855C2.04689 10.4183 1.74737 10.5847 1.52927 10.8214C1.33632 11.0308 1.19029 11.2789 1.10097 11.5493C1 11.8549 1 12.1975 1 12.8828V29.117C1 29.8023 1 30.145 1.10097 30.4506C1.19029 30.7209 1.33632 30.9691 1.52927 31.1785C1.74737 31.4151 2.0469 31.5815 2.64594 31.9143L17.4459 40.1366C18.0132 40.4517 18.2968 40.6092 18.5971 40.671C18.8629 40.7257 19.1371 40.7257 19.4029 40.671C19.7032 40.6092 19.9869 40.4517 20.5541 40.1366L35.3541 31.9143C35.9531 31.5815 36.2526 31.4151 36.4707 31.1785C36.6637 30.9691 36.8097 30.7209 36.899 30.4506C37 30.145 37 29.8023 37 29.117Z"
          stroke="#31006F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h5>ربط الطرود</h5>
    </div>
    <Tabs :selected="0">
      <Tab title="الإضافة" :steps="2"
        ><link-api
          :link="linkAdd"
          :success="successAdd"
          :apiCode="apiCodeAdd"
          :data="dataAdd"
          :showGovernmentApi="true"
      /></Tab>
      <Tab title="التعديل" :steps="4">
        <link-api
          :link="linkEdit"
          :success="successAdd"
          :apiCode="apiCodeEdit"
          :data="dataEdit"
          :showGovernmentApi="true"
        />
      </Tab>
      <Tab title="البحث" :steps="3">
        <link-api
          :link="linkSearch"
          :success="successSearch"
          :apiCode="apiCodeSearch"
          :data="dataSearch"
        />
      </Tab>
      <Tab title="الحالات" :steps="5">
        <link-api
          :link="linkStatus"
          :success="successSearch"
          :apiCode="apiCodeStatus"
          :data="dataStatus"
        />
      </Tab>
      <Tab title="الحذف" :steps="6">
        <link-api
          :link="linkDelete"
          :success="successDelete"
          :apiCode="apiCodeDelete"
          :data="dataDelete"
        />
      </Tab>
      <Tab :special="true" title="الربط الفوري" :steps="1"
        ><instantLinkApi
      /></Tab>
      <Tab title="طلب إعادة إرسال طرد مرتجع" :steps="7">
        <link-api
          :link="linkResendRequest"
          :success="successResendRequest"
          :apiCode="apiCodeResendRequest"
          :data="dataResendRequest"
        />
      </Tab>
      <Tab title="طلب إلغاء طرد" :steps="8">
        <link-api
          :link="linkCancel"
          :success="successResendRequest"
          :apiCode="apiCodeResendRequest"
          :data="dataCancel"
        />
      </Tab>
      <Tab title="Postman">
        <h3 style="display: flex; align-items: center;">
          رابط الAPI للخارجي علي postman
        </h3>
        <p>يمكنك عن طريق هذا الرابط معرفة جميع المعلومات اللازمة لمعرفة كيفية التعامل مع الAPIs.</p>

        <a href="https://documenter.getpostman.com/view/19468765/2s9Ye8hFRy" target="_blank"> https://documenter.getpostman.com/view/19468765/2s9Ye8hFRy</a>
      </Tab>
      
    </Tabs>
  </div>
</template>

<script>
import LinkApi from "@/views/components/LinkApi.vue";
import instantLinkApi from "@/views/components/InstantLinkApi.vue";
import Tabs from "@/layouts/components/Tabs.vue";
import Tab from "@/layouts/components/Tab.vue";
import { mapGetters } from "vuex";
import tour from "@/mixins/tour.js";

export default {
  name: "linkWitsApis",
  mixins: [tour],
  data() {
    return {
      steps: [
        {
          target: '[data-link-api-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: "الربط الفوري",
          },
          content: `من هنا يمكنك الربط اللحظي مع تربو`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="2"]', // We're using document.querySelector() under the hood
          header: {
            title: "اضافة طرد ب API",
          },
          content: `من هنا يمكنك اضافة طرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="3"]', // We're using document.querySelector() under the hood
          header: {
            title: "بحث عن طرد ب API",
          },
          content: `من هنا يمكنك بحث طرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="4"]', // We're using document.querySelector() under the hood
          header: {
            title: "تعديل طرد ب API",
          },
          content: `من هنا يمكنك تعديل طرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="5"]', // We're using document.querySelector() under the hood
          header: {
            title: "معرفة حالة الطرد API",
          },
          content: `من هنا يمكنك معرفة حالة الطرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="6"]', // We're using document.querySelector() under the hood
          header: {
            title: "حذف طرد ب API",
          },
          content: `من هنا يمكنك حذف طرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="7"]', // We're using document.querySelector() under the hood
          header: {
            title: "طلب إعادة إرسال طرد ب API",
          },
          content: `من هنا يمكنك طلب إعادة إرسال الطرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="8"]', // We're using document.querySelector() under the hood
          header: {
            title: "طلب إلغاء طرد ب API",
          },
          content: `من هنا يمكنك طلب إلغاء الطرد عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="9"]', // We're using document.querySelector() under the hood
          header: {
            title: "IDS الفروع",
          },
          content: `من هنا يمكنك معرفة ID كل فرع واستخدامه في API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="10"]', // We're using document.querySelector() under the hood
          header: {
            title: "إنشاء تذكرة دعم ب API",
          },
          content: `من هنا يمكنك إنشاء تذكرة دعم نوعها شكوي والتواصل معنا عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="11"]', // We're using document.querySelector() under the hood
          header: {
            title: "أنواع الشكاوي ب API",
          },
          content: `من هنا يمكنك الحصول علي جميع انواع الشكاوي لإنشاء تذكرة دعم عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="12"]', // We're using document.querySelector() under the hood
          header: {
            title: "إنشاء تذكرة دعم ب API",
          },
          content: `من هنا يمكنك إنشاء تذكرة دعم نوعها استفسار والتواصل معنا عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="13"]', // We're using document.querySelector() under the hood
          header: {
            title: "أنواع الاستفسارات ب API",
          },
          content: `من هنا يمكنك الحصول علي جميع انواع الاستفسارات لإنشاء تذكرة دعم عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '[data-link-api-tour-step="14"]', // We're using document.querySelector() under the hood
          header: {
            title: "إنشاء تذكرة دعم ب API",
          },
          content: `من هنا يمكنك إنشاء تذكرة دعم نوعها شكر أو إقتراح والتواصل معنا عن طريق ال API`,
          params: {
            enableScrolling: false,
          },
        },
      ],
      activeChildApi: 0,
      // DATA LINK API ADD
      linkAdd: "add-order",
      successAdd:
        '{"code":"D944","invoice_number":null,"bar_code":"1569782838144","is_order":"0","expected_branch":"المعادي"}"',
      apiCodeAdd: "",
      apiCodeEdit: "",
      dataAdd: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "main_client_code",
          value: JSON.parse(localStorage.getItem("userData")).clientId,
          descryption: "كود الراسل الرئيسي	",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "second_client",
          value: "احمد",
          descryption: "الراسل الفرعي",
          mandatory: "لا",
          class: "no",
        },
        {
          key: "receiver",
          value: "احمد حسن",
          descryption: "اسم المرسل اليه",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "phone1",
          value: "01199887098",
          descryption: "رقم تليفون١",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "phone2",
          value: "01199887098",
          descryption: "رقم تليفون٢",
          mandatory: "لا",
          class: "no",
        },
        {
          key: "government",
          value: "القاهرة",
          descryption: "المحافظة",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "area",
          value: "السيدة زينب",
          descryption: "المنطقة",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "address",
          value: "شارع بور سعيد",
          descryption: "العنوان",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "notes",
          value: "الطرد قابل للكسر",
          descryption: "ملاحظات",
          mandatory: "لا",
          class: "no",
        },
        {
          key: "invoice_number",
          value: "27",
          descryption: "رقم الفاتوره",
          mandatory: "لا",
          class: "no",
        },
        {
          key: "order_summary",
          value: "نظارات",
          descryption: "محتوى الطرد",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "amount_to_be_collected",
          value: "1750",
          descryption: "المبلغ المطلوب تحصيله",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "return_amount",
          value: "1250",
          descryption: "قيمة الارتجاع",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "api_followup_phone",
          value: "01199887098",
          descryption: "رقم المتابعة",
          mandatory: "لا",
          class: "no",
        },
        {
          key: "is_order",
          value: "0",
          descryption: `استلام طرد مع التحصيل
              لا = 0
              مرتجع جزئي = 1
              مرتجع استبدال = 2
              مرتجع استرجاع = 3`,
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "return_summary",
          value: "نظارة",
          descryption: `محتوي الطرد الراجع
          اجباري في حالة وجود مرتجع,
          is_order = 1 or 2 or 3`,
          mandatory: "لا",
          class: "no",
        },
        {
          key: "can_open",
          value: "1",
          descryption: `1 = "يمكن فتح الشحنة"
           0 = "لا يمكن فتح الشحنة"`,
          mandatory: "لا",
          class: "no",
        },
      ],
      dataEdit: [],
      // DATA LINK API SEARCH
      linkSearch: "search-order",
      successSearch:
        '{"status":"مع الراسل","returned_inventory_status":"علي الراسل","return_reason":"a","delay_reason":null,"branch":"الفرع الرئيسي","branch_number1":"لا يوجد","branch_number2":"لا يوجد","captain":"لا يوجد","captain_number1":"لا يوجد","captain_number2":"لا يوجد","received_amount":0}',
      apiCodeSearch: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }",
            "search_Key": "544545454545, D250,
            "main_client_code": "22233"}`,
      dataSearch: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "search_Key",
          value: "544545454545, D250",
          descryption: "ادخل البار كود او الكود",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "main_client_code",
          value: "22233",
          descryption: "كود الراسل الرئيسي",
          mandatory: "نعم",
          class: "yes",
        },
      ],
      // DATA LINK API EDIT
      linkEdit: "edit-order",
      // DATA LINK API STATUS
      linkStatus: "get-status",
      apiCodeStatus: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }",
            "from": "2020-09-27",
            "to": "2020-10-01"}`,
      dataStatus: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "from",
          value: "2020-09-27",
          descryption: "من",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "to",
          value: "2020-09-27",
          descryption: "إلي",
          mandatory: "نعم",
          class: "yes",
        },
      ],
      // DATA LINK API DELELTE
      linkDelete: "delete-order",
      successDelete: '{"status" : 1, "message" : "Deleted Successfully"}',
      apiCodeDelete: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }",
            "search_Key": "544545454545, D250}`,      
        dataDelete: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "search_Key",
          value: "544545454545, D250",
          descryption: "ادخل البار كود او الكود",
          mandatory: "نعم",
          class: "yes",
        },
      ],
      linkResendRequest: "resend-request",
      successResendRequest:
        '{"success": true, "error_msg": "تم الحفظ بنجاح .", "feed": [], "errors": []}',
      linkCancel: "canceled",
      apiCodeResendRequest: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }",
            "id": 29018799,
            "type": 1}`,
      dataResendRequest: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "id",
          value: "29018799",
          descryption: "ادخل الكود",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "type",
          value: "1",
          descryption: "نوع الطلب إعادة ارسال طرد مرتجع",
          mandatory: "نعم",
          class: "yes",
        },
      ],
      dataCancel: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "id",
          value: "29018799",
          descryption: "ادخل الكود",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "type",
          value: "1",
          descryption: "نوع الطلب إلغاء الطرد",
          mandatory: "نعم",
          class: "yes",
        },
      ],
    };
  },
  components: {
    LinkApi,
    instantLinkApi,
    Tabs,
    Tab,
  },
  computed: {
    ...mapGetters("userData", ["client"]),
  },
  mounted() {
    this.dataEdit = this.dataAdd.slice();
    this.dataEdit.push({
      key: "code",
      value: 123456789,
      descryption: "كود الطرد",
      mandatory: "نعم",
      class: "yes",
    });
    this.apiCodeAdd = `{"authentication_key": "${
      JSON.parse(localStorage.getItem("userData")).apiToken
    }",
    "main_client_code": ${
      JSON.parse(localStorage.getItem("userData")).clientId
    },
    "second_client":"حمزة عاطف",
    "receiver":"محمد اشرف",
    "phone1":"01000000000",
    "phone2":null,
    "api_followup_phone":"01100000000",
    "government":"القاهرة",
    "area":"السيدة زينب",
    "address":"شارع بورسعيد",
    "notes":"الطرد قابل للكسر", 
    "invoice_number":"27",
    "order_summary":"نظارات",
    "amount_to_be_collected":1750,
    "return_amount":1250,
    "is_order":0,
    "return_summary":"نظارة",
    "can_open": 1 }`;
    this.apiCodeEdit = `{"authentication_key": "${
      JSON.parse(localStorage.getItem("userData")).apiToken
    }",
    "main_client_code": ${
      JSON.parse(localStorage.getItem("userData")).clientId
    },
    "code":123456789,
    "second_client":"حمزة عاطف",
    "receiver":"محمد اشرف",
    "phone1":"01000000000",
    "phone2":null,
    "api_followup_phone":"01100000000",
    "government":"القاهرة",
    "area":"السيدة زينب",
    "address":"شارع بورسعيد",
    "notes":"الطرد قابل للكسر", 
    "invoice_number":"27",
    "order_summary":"نظارات",
    "amount_to_be_collected":1750,
    "return_amount":1250,
    "is_order":0,
    "return_summary":"نظارة",
    "can_open": 1 }`;
    this.$watch(
      () => {
        return this.$tours.linkWithApis.currentStep;
      },
      (val) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    );
    if (this.runWalkthrough()) {
      // this.startTour("linkWithApis");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_setting.scss";
  h3, p, a{
    margin-bottom: 16px;
  }
</style>
